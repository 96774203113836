import http from '@/utils/http';

/**
 * 轮播图
 */
export default {
  getList: {
    p: 'post,/manage/mobilesmsmsg/getlist',
    r: (data) => http({ url: '/manage/mobilesmsmsg/getlist', method: 'post', data })
  },
  getOne: {
    p: 'post,/manage/mobilesmsmsg/getone',
    r: (data) => http({ url: '/manage/mobilesmsmsg/getone', method: 'post', data })
  }
};
